import React, { useState } from "react";
import "./App.css";
import Chatbot from "./components/chatbot.jsx";
import { Routes, Route, Navigate } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { ThreadProvider } from "./components/context/createContext.jsx";
import Login from "./components/login/login.jsx";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <BrowserRouter>
      <Routes>
        {/* Default route */}
        <Route
          path="/"
          element={
            <div className="login-page">
              <Login setIsAuthenticated={setIsAuthenticated} />
            </div>
          }
        />
        {/* Chatbot route */}
        <Route
          path="/chatbot"
          element={
            isAuthenticated ? (
              <ThreadProvider>
                <div className="main-app">
                  <Chatbot />
                </div>
              </ThreadProvider>
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
