// Login.jsx
import React from "react";
import axios from "axios"
import "./login.css";
import mobius from "../../images/mobius-brand.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const Login = ({setIsAuthenticated}) => {
    const navigate = useNavigate()
    const backend = process.env.REACT_APP_BACKEND;
    const [userName,setUserName] = useState("")
    const [password,setUserPassword] = useState("")
    const handleLogin = async () =>{
        // console.log(userName,password)
        if (!userName || !password) {
            alert("Enter valid username and password")
        }
        try {
            const response = await axios.post(`${backend}/login`, {
                username: userName,
                password: password
            });
            const token = response.data.token;
            // Store token in localStorage or context for future requests
            localStorage.setItem('token', token);
            setIsAuthenticated(true);
            navigate("/chatbot");
        } catch (error) {
            if (error.response.status === 404 || error.response.status === 401) {
                alert("Please enter valid username and password");
            } else {
                alert("An error occurred. Please try again later.");
            }
        }
    }
    return (
        <div className="login_wrapper">
            <div className="login_container">
                <div><img src={mobius} alt="mobiusImage" /></div>
                <div className="userDetails">
                    <div className="user_name">
                        <input className="user_input" type="text" placeholder="Username" onChange={(e)=>setUserName(e.target.value)}/>
                    </div>
                    <div className="user_name">
                        <input className="user_input" type="password" placeholder="Password" onChange={(e)=>setUserPassword(e.target.value)}/>
                    </div>
                </div>
                <button className="submit" onClick={handleLogin}>Login</button>
            </div>
        </div>
    );
};

export default Login;
