import axios from "axios"
export const fileDownload =async (fileID)=>{
    console.log("file id --------------",fileID)
    const backend = process.env.REACT_APP_BACKEND; 
    try{
        const response = await axios.get(`${backend}/downloadFile?file_id=${fileID}`)
        console.log("response in download file----------",response)
        return response  
    }
    catch(error){
      return error
        // console.log(error)
    }         
}