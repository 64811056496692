import axios from "axios";

const backend = process.env.REACT_APP_BACKEND;
// console.log(backend)
// console.log("asdjf",process.env.REACT_APP_API_URL)
export const getAllAssistant = async (userId) => {
  try {
    const userAssistantResponse = await axios.get(`${backend}/assistants?user_id=${userId}`);
    const assistants = userAssistantResponse.data.Assistants
    return assistants;
  } catch (error) {
    return "Error fetching assistants";
  }

};

