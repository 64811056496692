import axios from "axios"
export const getAllthreads = async (userId,assistantId) =>{
    const backend = process.env.REACT_APP_BACKEND;
    // console.log(userId,assistantId)
    try{
      let threads
      // console.log("in app in threads-----------")
      const threadIds = await axios.get(`${backend}/allsessions?user_id=${userId}&assistantId=${assistantId}`);
      // console.log(threadIds.data.messages)
      threads = threadIds.data.messages
      // console.log("threads---", threads)
      return threads
    }catch(error){
      // console.error("Error fetching Threads:", error);
    }
  }

  