import "./sessions.css";
import { useContext } from "react";
import { ThreadContext } from "../context/createContext.jsx";
import { MdDelete } from "react-icons/md";
import { deleteThread } from "../../utils/delete.js"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const DisplaySessions = (threadDetails) => {
  const {  setThreadId, setDeleteThread,setFileId } = useContext(ThreadContext);
  const details = threadDetails.data;
  // console.log("in displaySessions", details);
  const handleSession = (id) => {
    // console.log(id)
    setThreadId(id);
    setFileId("");    
  };
  const handleDeleteSession = async (thread) => {
    // console.log("threadid in delete ",thread)
    await deleteThread(thread).then((response) => {
      // console.log("in deleted thread")
    })
    setDeleteThread((prevState) => !prevState)
    toast.success('session deleted successfully!', { position: toast.POSITION.BOTTOM_RIGHT });
    setThreadId("")
  }
  return (
    <>
      <div className="threads_container scrollbar" id="style-1">
        {details.map((threadId, index) => {
          return (
            <div
              className="session thread_title"
              key={index}
              onClick={() => handleSession(threadId.thread_id)} >
              {threadId.thread_title}
              <span className="delete_session" onClick={() => handleDeleteSession(threadId.thread_id)} >
                <MdDelete />
              </span>
            </div>
          );
        })}
          <ToastContainer />
      </div>
    </>
  );
};
export default DisplaySessions;
