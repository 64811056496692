import axios from "axios"
// const backend = process.env.React_APP_BACKEND
export const fileupload = async(uploadfile) => {
  console.log(uploadfile)
  const backend = process.env.REACT_APP_BACKEND;  
  const formData = new FormData();
  formData.append('file', uploadfile);    
        try{
            const response = await axios.post(`${backend}/uploadFile`,formData,
            {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            )
            // console.log("messages",response)
            return response    
        }
        catch(error){
          return error
            // console.log(error)
        }         
      
}