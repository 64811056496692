import React from 'react';
import Content from "./chatmessages/content.jsx";
import "./chatbot.css";
import { useContext } from 'react';
import { FaRobot } from "react-icons/fa6";
import { ThreadContext } from './context/createContext.jsx';



const Chatbot = () => {
    const { display, setDisplay } = useContext(ThreadContext);
    const { max } = useContext(ThreadContext);
    const toggleDisplay = () => { // to show sessions
        setDisplay((prevDisplay) => !prevDisplay);
    };
    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    }
    return (
        <>
            <div className='logout_bt'>
                <button onClick={handleLogout}>Logout</button>
            </div>
            {display ? (
                <div className={` ${max ? "max_chats" : "main_chatbot"}`}>
                    <Content />
                </div>)
                : (
                    <div className="chatbox__button" >
                        <button onClick={() => toggleDisplay()}>
                            <FaRobot size={30} />
                        </button>
                    </div>
                )
            }

        </>
    );
};

export default Chatbot;
