import "./input.css"
import { useState, useContext } from "react";
import { ThreadContext } from "../context/createContext.jsx";
import { MdOutlineFileUpload } from "react-icons/md";
import { fileupload } from "../../utils/fileUpload.js";
import { AiOutlineSend } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import { MdOutlineFileDownload } from "react-icons/md";
import { inputResponse } from "../../utils/sendinput.js";
import { fileDownload } from "../../utils/fileDownload.js";
import axios from "axios";

const Input = () => {
    const backend = process.env.REACT_APP_BACKEND; 
    
    const [downloadFile, setDownloadFile] = useState(true)
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [hashSuggestions,setHashSuggestions] = useState(false)
    const [showHashSuggestions,setshowHashSuggestions] = useState([])
    const [annotation,setAnnotation] = useState()
    
    const {
        thread_id,
        setThreadId,
        assistant_id,
        user_id,
        setDisplayMessage,
        newThread,
        fileId, setFileId, file_id, setDownloadFileID, setStage,
        load, setLoad,inputValue,setInputValue
    } = useContext(ThreadContext);
    
    async function handleSendMessage() {
        
        // setInputValue("")
        setLoad(false)
        setStage(0)
          setTimeout(() => {
            setStage(1); 
          }, 4000);
          setTimeout(() => {
            setStage(2); 
          }, 8000);
        const response = await inputResponse(user_id, assistant_id, thread_id, fileId, inputValue)
        // console.log("--------in input---------",response)
        if (response.error) {
            alert(response.error)
            setInputValue("")
            setLoad(true)
        } else {
            setDisplayMessage(response.messages)
            setDownloadFileID(response.file_id)
            // setDisplayMessage(prevMessages => [...prevMessages, ...response.messages]);
            setThreadId(response.thread_id)
            setInputValue("")
            setLoad(true)
            setStage()
            if (response.file_id) {
                setDownloadFile(true)
            }
        }
    }
    const handleFileUpload = async (e) => {
        const uploadedFile = e.target.files[0];
        // console.log(uploadedFile)
        const response = await fileupload(uploadedFile);
        if(response.data.file_id){
            setFileId(response.data.file_id)
            alert("File uploaded successfully")
        }
        else{
            alert("Error in uploading a file")
        }
    }
    const handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            // console.log("in key pressed")
            e.preventDefault();
            if (inputValue === "") {
                alert("please enter valid input value")
            } else {
                handleSendMessage()
            }
        }
    }
    const handleFileDownload = async (e) => {
        e.preventDefault()
        if(file_id!==""){
        const response = await fileDownload(file_id)
        const contentType = response.headers['content-type'];
        console.log("content---------------", contentType)
        // const contentType = 'application/xml'
        const contentTypeToExtension = {
            'application/xml': 'xml',
            'application/msword': 'doc',
            'application/xml; charset=utf-8': 'xml'
        };

        const fileExtension = contentTypeToExtension[contentType] || 'txt';
        console.log("-------------------", contentTypeToExtension[contentType])

        console.log("data----------", response)
        const blob = new Blob([response.data]);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);

        // Set the filename for the downloaded file
        downloadLink.download = `downloaded_file.${fileExtension}`;
        console.log(downloadLink.download)
        // Append the link to the document
        document.body.appendChild(downloadLink);

        // Trigger a click on the link to start the download
        downloadLink.click();

        // Remove the link from the document
        document.body.removeChild(downloadLink);
    }else{
        alert("File is not generated")
    }
    }
    const handleInputChange = async (e) => {
        const newInputValue = e.target.value;
        setInputValue(newInputValue);

        // Check if "@" is present in the input value
        
        if (newInputValue.includes('@')) {
            const response = await axios.get(`${backend}/getAllAnnotations?assistant_id=${assistant_id}`)
            console.log(response.data.Annotations)
            const mockSuggestions = response.data.Annotations;
            console.log('Show drop-ups');
            setSuggestions(mockSuggestions);
            setShowSuggestions(true);
            console.log(suggestions, showSuggestions)
        }

        else {
            setShowSuggestions(false);
        }
        if(newInputValue.includes('#')){
            const response = await axios.get(`${backend}/getAllParameters?annotations_name=${annotation}`)
            console.log("params-----------",response)
            const mockSuggestions =response.data.Parameters;
            setshowHashSuggestions(mockSuggestions);
            setHashSuggestions(true);
            console.log(hashSuggestions, showHashSuggestions)
        }
        else {
            setHashSuggestions(false);
        }
        const textarea = e.target;
            textarea.style.height = 'auto';
            if(textarea.scrollHeight>70){
                textarea.style.height ="70px"
            }
        
            else{
                textarea.style.height = `${textarea.scrollHeight}px`
            }
            
        console.log("-------------------------------",hashSuggestions, showHashSuggestions)
    };
    console.log(suggestions, showSuggestions)
    const handleSuggestionClick = (suggestion) => {
        // Handle suggestion click, you can update the input value or perform any action
        const replace_input = suggestion.replace("@", "")
        setInputValue(` ${replace_input} `);
        setShowSuggestions(false);
        setAnnotation(suggestion)
        setSuggestions([])
    };
    const handleHashSuggestionClick = (suggestion)=>{
        console.log("hash suggestion in fucntion",suggestion)
        const replace_input = inputValue.replace("#", "")
        suggestion = suggestion.replace("#","")
        setInputValue(`${replace_input} ${suggestion} `);
        setHashSuggestions(false);
        setshowHashSuggestions([])
    }
    console.log(inputValue)
    return (
        <>  
     
        
        <div className="chatbox_footer">
            <div className="chatbox_file">
                <label htmlFor="fileUpload" className="file-upload-label">
                    <span>
                        <MdOutlineFileUpload size={22} />
                        <input
                            type="file"
                            id="fileUpload"
                            name="uploadedFile"
                            // disabled={thread_id ? false : true}
                            style={{ display: "none" }}
                            onChange={handleFileUpload}
                        />
                    </span>
                </label>
            </div>
            <div className="chatbox_input" style={{ position: 'relative' }}>
                <textarea
                    type="text"
                    placeholder={
                        newThread || thread_id
                            ? "Type your message..."
                            : "Click on a new chat..../Prev Sessions"
                    }
                    onChange={handleInputChange}
                    value={inputValue}
                    disabled={thread_id || newThread ? false : true}
                    onKeyDown={handleKeyPress}
                />
                {showSuggestions && (
                    <div className="drop-up">
                        {suggestions.map((suggestion, index) => (
                            <div
                                key={index}
                                onClick={() => handleSuggestionClick(suggestion)}
                                className="suggestion-item"
                            > 
                                {`${suggestion}`}
                            </div>
                        ))}
                    </div>
                )}
                {hashSuggestions && (
                    <div className="drop-up">
                        {showHashSuggestions.map((suggestion, index) => (
                            <div
                                key={index}
                                onClick={() => handleHashSuggestionClick(suggestion)}
                                className="suggestion-item"
                            >
                                {`${suggestion}`}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            
            <div className="chatbox_send">
                <button
                    className=" send__button"
                    onClick={handleSendMessage}
                    disabled={inputValue ? false : true}

                >
                    {load ? (
                        <AiOutlineSend size={18} style={{ color: "white" }} />
                    ) : (
                        <ClipLoader
                            color="white"
                            size={24}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    )}
                </button>
            </div>
            <div className="file_download"><MdOutlineFileDownload size={22} style={{ color: "white" }} onClick={downloadFile ? handleFileDownload : null} /></div>
        </div>

        </>
    )
}
export default Input