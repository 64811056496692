import axios from 'axios';

export const deleteThread = async (threadId) => {
      const backend = process.env.REACT_APP_BACKEND;        
        try{
            const response = await axios.delete(`${backend}/thread?thread_id=${threadId}`)
            // console.log("messages",response)
            return response    
        }
        catch(error){
            // console.log(error.message)
            return error.message
        }         
}